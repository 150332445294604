<template>
  <el-card v-loading="loading">


    <div style="display: flex; gap: 20px;align-items: flex-start;align-content: flex-start;">
      <user-unique-prop-values-select
        placeholder="Локация"
        v-model="filters.locations"
        prop="location"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>

      <user-unique-prop-values-select
        placeholder="Дирекция"
        v-model="filters.directions"
        prop="direction"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>


      <user-unique-prop-values-select
        placeholder="Цех"
        style="width: 100px"
        v-model="filters.workshops"
        prop="workshop"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>

      <user-unique-prop-values-select
        v-model="filters.bands"
        placeholder="Бенд"
        style="width: 150px"
        prop="band"
        multiple
        @selected="filtersChanged"
      ></user-unique-prop-values-select>

      <el-select
        v-model="filters.subordinates"
        placeholder="Подчиненные"
        @change="filtersChanged"
      >
        <el-option label="Мои непосредственные подчиненные (HP)" value="immediate_subordinates_tree_direct"></el-option>
        <el-option label="Все мои подчиненные (HP)" value="all_subordinates_tree_direct"></el-option>
      </el-select>

      <user-select
        placeholder="ФИО НР"
        style="width: 300px"
        v-model="filters.direct_chief_user_id"
        :multiple="false"
        @selected="filtersChanged"
      ></user-select>

      <cards-year-select
        style="width:100px"
        v-model="filters.year"
        :clearable="false"
        :multiple="false"
        @change="filtersChanged"
      ></cards-year-select>
    </div>


    <el-row :gutter="60" style="margin-top: 20px">
      <el-col :span="12" >
        <div style="display: flex;justify-content: space-between">
          <div class="calibration-block-column-name">Распределение итоговых оценок</div>
          <div v-if="$canAndRulesPass(user, 'calibration.export_table')">
            <el-button
              type="text"
              size="mini"
              icon="fas fa-file-excel"
              @click="exportToExcel"
            >
              Экспорт в XLSX
            </el-button>
          </div>
        </div>
        <div>

          <calibration-table :cells="cells"></calibration-table>
        </div>
      </el-col>


      <el-col :span="12">
        <div class="calibration-block-column-name">График</div>
        <calibration-chart :cells="cells"></calibration-chart>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>

import requestSender from "@/api/base/requestSender";
import CalibrationTable from "@/componentsCompany/smz/calibration/CalibrationTable.vue";
import CalibrationChart from "@/componentsCompany/smz/calibration/CalibrationChart.vue";
import CardsYearSelect from "@/components/filters/cards/CardsYearSelect.vue";
import UserSelect from "@/components/filters/users/UserSelect.vue";
import UserUniquePropValuesSelect from "@/components/filters/users/UserUniquePropValuesSelect.vue";
import {downloader} from "@/mixins/downloader";
import {mapGetters} from "vuex";


export default {
  name: "calibration-block",
  mixins: [downloader],
  components: {UserUniquePropValuesSelect, UserSelect, CardsYearSelect, CalibrationChart, CalibrationTable},
  props: {},

  mounted() {
    this.getResult();
  },
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      loading: false,
      filters:{
        locations: [],
        subdivisions: [],
        directions: [],
        workshops: [],
        bands: [],
        subordinates: 'immediate_subordinates_tree_direct',
        direct_chief_user_id: null,
        year: (new Date()).getFullYear(),
      },
      cells: {},
    }
  },

  methods: {
    getResult() {
      this.loading = true;

      requestSender('post', 'calibration/calibration-result', {
        ...this.filters,
      })
        .then(data => {
          this.cells = data.columns;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    filtersChanged(){
      this.getResult();
    },

    exportToExcel() {
      this.downloadFile(
        false,
        this.$api.exportImport.export,
        {
          entity: 'calibration_table',
          data: {
            columns: this.cells,
          }}
      )
    },
  },
}
</script>

<style>
.calibration-block-column-name {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

</style>